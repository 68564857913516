import { Provider } from "./shared";

export enum ConnectionStatus {
  OK = "OK",
  REQUIRES_AUTHENTICATION = "REQUIRES_AUTHENTICATION",
  REQUIRES_AUTHENTICATION_NOTIFIED = "REQUIRES_AUTHENTICATION_NOTIFIED",
  DISABLED = "DISABLED",
  FETCHING_INITIAL_DATA = "FETCHING_INITIAL_DATA",
  ERROR = "ERROR",
}

export interface ConnectionResponse {
  id: number;
  sub: string;
  displayName: string;
  createdAt: string;
  createdBy: string;
  status: ConnectionStatus;
  provider: Provider;
  displayError: string | null;
  webhookUrl: string | null;
  adAccountId: number | null;
}

export interface Connection {
  id: number;
  sub: string;
  providerId: string;
  displayName: string;
  createdAt: string;
  createdBy: string;
  status: ConnectionStatus;
  provider: Provider;
  providerDisplayName: string;
  displayStatus: string;
  tooltip: string;
  logo: string;
  webhookUrl: string | null;
  adAccountId: number | null;
}

export const statusToDisplayStatus: Record<ConnectionStatus, string> = {
  [ConnectionStatus.OK]: "Active",
  [ConnectionStatus.DISABLED]: "Disabled",
  [ConnectionStatus.REQUIRES_AUTHENTICATION]: "Requires Authentication",
  [ConnectionStatus.REQUIRES_AUTHENTICATION_NOTIFIED]:
    "Requires Authentication",
  [ConnectionStatus.FETCHING_INITIAL_DATA]: "Fetching data",
  [ConnectionStatus.ERROR]: "Error",
};

export const statusToTooltip: Record<ConnectionStatus, string> = {
  OK: "Connection is active",
  FETCHING_INITIAL_DATA: "Connection is fetching historic data",
  REQUIRES_AUTHENTICATION: "Click on 3 dots to reauthenticate",
  REQUIRES_AUTHENTICATION_NOTIFIED: "Click on 3 dots to reauthenticate",
  DISABLED: "Connection is disabled",
  ERROR: "Connection has an error",
};

export type FetchInitialMetricsJobStatus = "Running" | "Completed" | "Failed";
export type FetchInitialMetricsJobStatusResponse = {
  status: FetchInitialMetricsJobStatus;
  getStatusUrl: string;
};
export type FetchInitialMetricsJobStatusResultSuccess = [
  { status: FetchInitialMetricsJobStatus; getStatusUrl: string },
  true,
];
export type FetchInitialMetricsJobStatusTesultFailure = [
  string | null,
  true | false,
];
export type FetchInitialMetricsJobStatusResult =
  | FetchInitialMetricsJobStatusResultSuccess
  | FetchInitialMetricsJobStatusTesultFailure;
